<template>
  <div>
    <div class="">
      <div class="container px-4 mx-auto mb-8">
        <span class="text-lg font-bold">Filtra per città: </span>
        <span
          class="text-lg cursor-pointer"
          :class="{ 'font-bold text-red': city == 'all' }"
          @click="filterCities('all')"
          >Tutte</span
        >
        <span
          v-for="(event_array, name_array) in events"
          :key="name_array"
          class="text-lg cursor-pointer capitalize"
          @click="filterCities(name_array)"
        >
          |
          <span :class="{ 'font-bold text-red': city == name_array }">
            {{ name_array }}
          </span>
        </span>
      </div>
    </div>
    <div class="">
      <div class="container px-4 mx-auto">
        <!-- <div class="my-16">Solo pubblicati: {{ only_published }}</div> -->
        <div class="mb-8">
          <!-- <pre class="bg-gray-200">
        {{ events }}
        </pre> -->
          <div
            v-for="(event_array, name_array) in events"
            :key="name_array"
            class="mb-12 md:mb-16"
            :id="name_array"
            v-show="event_array.display"
          >
            <!-- {{ name_array }} -->
            <!-- {{ event_array }} -->
            <!-- {{ event_array.display }} -->
            <div class="">
              <div>
                <h2 class="text-lg font-bold text-red uppercase mb-4">
                  {{ name_array }}
                </h2>
              </div>
              <div v-if="!only_passed">
                <!-- Future events -->
                <div
                  v-if="event_array.future.length > 0"
                  class="
                    grid
                    gap-4
                    grid-cols-1
                    sm:grid-cols-2
                    md:grid-cols-2
                    lg:grid-cols-3
                    3xl:grid-cols-4
                    mb-6
                  "
                >
                  <div
                    v-for="(event, index) in event_array.future"
                    :key="index"
                    class="event"
                  >
                    <div
                      class="
                        flex flex-col
                        justify-between
                        h-full
                        border
                        transition-colors
                        duration-300
                        ease-in-out
                      "
                      v-bind:class="[
                        only_published == true &&
                        event.occupied_seats >= event.seats
                          ? 'opacity-40'
                          : 'hover:border-red cursor-pointer',
                      ]"
                    >
                      <div
                        class="flex flex-col justify-between h-full px-2 py-2"
                        @click="
                          only_published == true &&
                          event.occupied_seats >= event.seats
                            ? ''
                            : singleEvent(only_published, event.id)
                        "
                      >
                        <div class="mb-4">
                          <p class="text-lg font-bold text-red">
                            {{ event.title }}
                          </p>
                          <p class="text-lg">{{ event.subtitle }}</p>
                        </div>
                        <div class="">
                          <div class="">
                            <p class="inline-block">
                              {{ event.day }} {{ event.date }}
                            </p>
                            <span class="inline-block mx-2">-</span>
                            <p class="inline-block">
                              {{ event.time.hour }}:{{ event.time.minute }}
                            </p>
                          </div>
                          <div class="">
                            <p class="">{{ event.location }}</p>
                            <p class="">{{ event.city }}</p>
                          </div>
                          <div v-if="!only_published">
                            <p class="">
                              Numero di posti prenotati:
                              {{ event.occupied_seats }}/{{ event.seats }}
                            </p>
                          </div>
                        </div>
                      </div>
                      <!-- Dashboard user -->
                      <div v-if="only_published" class="event-button">
                        <div class="flex justify-between border-t">
                          <div class="inline-block w-full px-2 py-2">
                            <p
                              v-if="event.occupied_seats >= event.seats"
                              class="font-bold"
                            >
                              Posti esauriti
                            </p>
                            <p
                              v-else
                              class="font-bold"
                              @click="
                                only_published == true &&
                                event.occupied_seats >= event.seats
                                  ? ''
                                  : singleEvent(only_published, event.id)
                              "
                            >
                              Prenota
                            </p>
                          </div>
                          <div
                            v-if="event.video_conference"
                            class="inline-block border-l px-2 py-2 cursor-help"
                            @click="popup()"
                          >
                            <svg
                              class="inline-block h-4 w-4"
                              viewBox="0 0 24 24"
                              fill="none"
                              stroke="currentColor"
                              stroke-width="2"
                              stroke-linecap="round"
                              stroke-linejoin="round"
                            >
                              <circle cx="12" cy="12" r="10" />
                              <line x1="12" y1="16" x2="12" y2="12" />
                              <line x1="12" y1="8" x2="12.01" y2="8" />
                            </svg>
                          </div>
                        </div>
                      </div>
                      <!-- Dashboard admin -->
                      <div v-else class="event-button">
                        <div class="flex justify-between border-t">
                          <div
                            class="inline-block w-full px-2 py-2"
                            @click="
                              only_published == true &&
                              event.occupied_seats >= event.seats
                                ? ''
                                : singleEvent(only_published, event.id)
                            "
                          >
                            <p
                              v-if="event.occupied_seats >= event.seats"
                              class="font-bold"
                            >
                              <svg
                                class="inline-block h-4 w-4 mr-1 mb-1"
                                viewBox="0 0 24 24"
                                fill="none"
                                stroke="currentColor"
                                stroke-width="2"
                                stroke-linecap="round"
                                stroke-linejoin="round"
                              >
                                <path
                                  d="M10.29 3.86L1.82 18a2 2 0 0 0 1.71 3h16.94a2 2 0 0 0 1.71-3L13.71 3.86a2 2 0 0 0-3.42 0z"
                                />
                                <line x1="12" y1="9" x2="12" y2="13" />
                                <line x1="12" y1="17" x2="12.01" y2="17" />
                              </svg>
                              Posti esauriti
                            </p>
                            <p v-else-if="event.draft" class="font-bold">
                              <svg
                                class="inline-block h-4 w-4 mr-1 mb-1"
                                width="24"
                                height="24"
                                viewBox="0 0 24 24"
                                xmlns="http://www.w3.org/2000/svg"
                                fill="none"
                                stroke="currentColor"
                                stroke-width="2"
                                stroke-linecap="round"
                                stroke-linejoin="round"
                              >
                                <path
                                  d="M11 4H4a2 2 0 0 0-2 2v14a2 2 0 0 0 2 2h14a2 2 0 0 0 2-2v-7"
                                />
                                <path
                                  d="M18.5 2.5a2.121 2.121 0 0 1 3 3L12 15l-4 1 1-4 9.5-9.5z"
                                />
                              </svg>
                              Evento in bozza
                            </p>
                            <p v-else class="font-bold py-2 mb-1"></p>
                          </div>
                          <div
                            v-if="event.video_conference"
                            class="inline-block border-l px-2 py-2 cursor-help"
                            @click="popup()"
                          >
                            <svg
                              class="inline-block h-4 w-4"
                              viewBox="0 0 24 24"
                              fill="none"
                              stroke="currentColor"
                              stroke-width="2"
                              stroke-linecap="round"
                              stroke-linejoin="round"
                            >
                              <circle cx="12" cy="12" r="10" />
                              <line x1="12" y1="16" x2="12" y2="12" />
                              <line x1="12" y1="8" x2="12.01" y2="8" />
                            </svg>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <div v-else>
                  <h2 class="text-lg mb-4">Non ci sono eventi.</h2>
                </div>
              </div>
              <div v-else>
                <!-- Past events -->
                <div
                  v-if="event_array.past.length > 0"
                  class="
                    grid
                    gap-4
                    grid-cols-1
                    sm:grid-cols-2
                    md:grid-cols-2
                    lg:grid-cols-3
                    3xl:grid-cols-4
                    mb-6
                  "
                >
                  <div
                    v-for="(event, index) in event_array.past"
                    :key="index"
                    class="event"
                  >
                    <div
                      class="
                        flex flex-col
                        justify-between
                        h-full
                        border
                        transition-colors
                        duration-300
                        ease-in-out
                      "
                      v-bind:class="[
                        only_published == true &&
                        event.occupied_seats >= event.seats
                          ? 'opacity-40'
                          : 'hover:border-red cursor-pointer',
                      ]"
                    >
                      <div
                        class="flex flex-col justify-between h-full px-2 py-2"
                        @click="
                          only_published == true &&
                          event.occupied_seats >= event.seats
                            ? ''
                            : singleEvent(only_published, event.id)
                        "
                      >
                        <div class="mb-4">
                          <p class="text-lg font-bold text-red">
                            {{ event.title }}
                          </p>
                          <p class="text-lg">{{ event.subtitle }}</p>
                        </div>
                        <div class="">
                          <div class="">
                            <p class="inline-block">
                              {{ event.day }} {{ event.date }}
                            </p>
                            <span class="inline-block mx-2">-</span>
                            <p class="inline-block">
                              {{ event.time.hour }}:{{ event.time.minute }}
                            </p>
                          </div>
                          <div class="">
                            <p class="">{{ event.location }}</p>
                            <p class="">{{ event.city }}</p>
                          </div>
                          <div v-if="!only_published">
                            <p class="">
                              Numero di posti prenotati:
                              {{ event.occupied_seats }}/{{ event.seats }}
                            </p>
                          </div>
                        </div>
                      </div>
                      <!-- Dashboard user -->
                      <div v-if="only_published" class="event-button">
                        <div class="flex justify-between border-t">
                          <div class="inline-block w-full px-2 py-2">
                            <p
                              v-if="event.occupied_seats >= event.seats"
                              class="font-bold"
                            >
                              Posti esauriti
                            </p>
                            <p
                              v-else
                              class="font-bold"
                              @click="
                                only_published == true &&
                                event.occupied_seats >= event.seats
                                  ? ''
                                  : singleEvent(only_published, event.id)
                              "
                            >
                              Prenota
                            </p>
                          </div>
                          <div
                            v-if="event.video_conference"
                            class="inline-block border-l px-2 py-2 cursor-help"
                            @click="popup()"
                          >
                            <svg
                              class="inline-block h-4 w-4"
                              viewBox="0 0 24 24"
                              fill="none"
                              stroke="currentColor"
                              stroke-width="2"
                              stroke-linecap="round"
                              stroke-linejoin="round"
                            >
                              <circle cx="12" cy="12" r="10" />
                              <line x1="12" y1="16" x2="12" y2="12" />
                              <line x1="12" y1="8" x2="12.01" y2="8" />
                            </svg>
                          </div>
                        </div>
                      </div>
                      <!-- Dashboard admin -->
                      <div v-else class="event-button">
                        <div class="flex justify-between border-t">
                          <div
                            class="inline-block w-full px-2 py-2"
                            @click="
                              only_published == true &&
                              event.occupied_seats >= event.seats
                                ? ''
                                : singleEvent(only_published, event.id)
                            "
                          >
                            <p
                              v-if="event.occupied_seats >= event.seats"
                              class="font-bold"
                            >
                              <svg
                                class="inline-block h-4 w-4 mr-1 mb-1"
                                viewBox="0 0 24 24"
                                fill="none"
                                stroke="currentColor"
                                stroke-width="2"
                                stroke-linecap="round"
                                stroke-linejoin="round"
                              >
                                <path
                                  d="M10.29 3.86L1.82 18a2 2 0 0 0 1.71 3h16.94a2 2 0 0 0 1.71-3L13.71 3.86a2 2 0 0 0-3.42 0z"
                                />
                                <line x1="12" y1="9" x2="12" y2="13" />
                                <line x1="12" y1="17" x2="12.01" y2="17" />
                              </svg>
                              Posti esauriti
                            </p>
                            <p v-else-if="event.draft" class="font-bold">
                              <svg
                                class="inline-block h-4 w-4 mr-1 mb-1"
                                width="24"
                                height="24"
                                viewBox="0 0 24 24"
                                xmlns="http://www.w3.org/2000/svg"
                                fill="none"
                                stroke="currentColor"
                                stroke-width="2"
                                stroke-linecap="round"
                                stroke-linejoin="round"
                              >
                                <path
                                  d="M11 4H4a2 2 0 0 0-2 2v14a2 2 0 0 0 2 2h14a2 2 0 0 0 2-2v-7"
                                />
                                <path
                                  d="M18.5 2.5a2.121 2.121 0 0 1 3 3L12 15l-4 1 1-4 9.5-9.5z"
                                />
                              </svg>
                              Evento in bozza
                            </p>
                            <p v-else class="font-bold py-2 mb-1"></p>
                          </div>
                          <div
                            v-if="event.video_conference"
                            class="inline-block border-l px-2 py-2 cursor-help"
                            @click="popup()"
                          >
                            <svg
                              class="inline-block h-4 w-4"
                              viewBox="0 0 24 24"
                              fill="none"
                              stroke="currentColor"
                              stroke-width="2"
                              stroke-linecap="round"
                              stroke-linejoin="round"
                            >
                              <circle cx="12" cy="12" r="10" />
                              <line x1="12" y1="16" x2="12" y2="12" />
                              <line x1="12" y1="8" x2="12.01" y2="8" />
                            </svg>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <div v-else>
                  <h2 class="text-lg mb-4">Non ci sono eventi.</h2>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>


<script>
// Components
import EventsDataService from "@/services/EventsDataService";

export default {
  name: "Read",
  props: {
    only_published: Boolean,
    only_passed: Boolean,
  },
  data() {
    return {
      events: {},
      city: "all",
    };
  },
  mounted() {
    // console.log("Props: ", this.only_published);
    this.getAllEvents();
    // .then(() => {
    //   this.today();
    // });
  },
  methods: {
    async getAllEvents() {
      this.events = await EventsDataService.getAll()
        .orderBy("date", "asc")
        .onSnapshot(this.onDataChange);
    },
    onDataChange(items) {
      var today = new Date();
      var dd = String(today.getDate()).padStart(2, "0");
      var mm = String(today.getMonth() + 1).padStart(2, "0"); //January is 0!
      var yyyy = today.getFullYear();
      today = dd + "/" + mm + "/" + yyyy;
      // document.write(today);
      // console.log("TODAY: ", today);

      let _today = this.process(today);
      // console.log(_today);

      let _events = {
        torino: {
          display: true,
          past: [],
          future: [],
        },
        novara: {
          display: true,
          past: [],
          future: [],
        },
        verbania: {
          display: true,
          past: [],
          future: [],
        },
        altre: {
          display: true,
          past: [],
          future: [],
        },
      };

      // Sort array by date
      // console.log("Before - items.docs:", items.docs);
      let docs = this.sortByDate(items.docs);
      // console.log("After - docs:", docs);

      // Get only published events
      // console.log("ONLY: ", this.only_published);
      // Dashboard user
      if (this.only_published) {
        docs.forEach((item) => {
          // console.log("Item: ", item);
          let id = item.id;
          // console.log("ID: ", id);
          let data = item.data();
          // console.log("Data: ", data);
          let _date = this.process(data.date);
          // console.log("Date: ", _date);

          if (!data.draft) {
            if (data.city == "Torino") {
              if (_date >= _today) {
                _events.torino.future.push({
                  id: id,
                  ...data,
                });
              } else {
                _events.torino.past.push({
                  id: id,
                  ...data,
                });
              }
            } else if (data.city == "Novara") {
              if (_date >= _today) {
                _events.novara.future.push({
                  id: id,
                  ...data,
                });
              } else {
                _events.novara.past.push({
                  id: id,
                  ...data,
                });
              }
            } else if (data.city == "Verbania") {
              if (_date >= _today) {
                _events.verbania.future.push({
                  id: id,
                  ...data,
                });
              } else {
                _events.verbania.past.push({
                  id: id,
                  ...data,
                });
              }
            } else if (data.city == "Altre") {
              if (_date >= _today) {
                _events.altre.future.push({
                  id: id,
                  ...data,
                });
              } else {
                _events.altre.past.push({
                  id: id,
                  ...data,
                });
              }
            }
          }
        });
      }
      // Dashboard admin
      else {
        docs.forEach((item) => {
          // console.log("Item: ", item);
          let id = item.id;
          // console.log("ID: ", id);
          let data = item.data();
          // console.log("Data: ", data);
          let _date = this.process(data.date);
          // console.log("Date: ", _date);

          if (data.city == "Torino") {
            if (_date >= _today) {
              _events.torino.future.push({
                id: id,
                ...data,
              });
            } else {
              _events.torino.past.push({
                id: id,
                ...data,
              });
            }
          } else if (data.city == "Novara") {
            if (_date >= _today) {
              _events.novara.future.push({
                id: id,
                ...data,
              });
            } else {
              _events.novara.past.push({
                id: id,
                ...data,
              });
            }
          } else if (data.city == "Verbania") {
            if (_date >= _today) {
              _events.verbania.future.push({
                id: id,
                ...data,
              });
            } else {
              _events.verbania.past.push({
                id: id,
                ...data,
              });
            }
          } else if (data.city == "Altre") {
            if (_date >= _today) {
              _events.altre.future.push({
                id: id,
                ...data,
              });
            } else {
              _events.altre.past.push({
                id: id,
                ...data,
              });
            }
          }
        });
      }

      // console.log("Events: ", _events);
      this.events = _events;
      // this.today(_events);
    },
    process(date) {
      let parts = date.split("/");
      return new Date(parts[2], parts[1] - 1, parts[0]);
    },
    // https://stackoverflow.com/questions/23084782/how-sort-array-date-javascript-dd-mm-yyyy
    sortByDate(array) {
      // console.log("Before - Array: ", array);
      array.sort((a, b) => {
        let first = a.data().date.split("/").reverse().join();
        // console.log("first: ", first);
        let second = b.data().date.split("/").reverse().join();
        // console.log("second: ", second);
        return first < second ? -1 : first > second ? 1 : 0;
      });
      // console.log("After - Array: ", array);
      return array;
    },
    singleEvent(only_published, event_id) {
      if (only_published) {
        this.$router.push({ name: "Event", params: { id: event_id } });
      } else {
        this.$router.push({ name: "Reservations", params: { id: event_id } });
      }
    },
    popup() {
      alert(
        "L'evento sarà trasmesso in video collegamento all'interno di una sala del Circolo."
      );
    },
    filterCities(city) {
      this.city = city;
      let _events = this.events;
      // console.log(city);
      if (city == "all") {
        Object.keys(_events).forEach((property) => {
          _events[property].display = true;
        });
      } else {
        Object.keys(_events).forEach((property) => {
          _events[property].display = false;
        });
        _events[city].display = true;
      }
    },
  },
};
</script>