<template>
  <div class="container mx-auto">
    <div class="">
      <p class="inline-block text-white cursor-pointer" @click="Logout()">
        Logout
      </p>
      <span class="inline-block text-white mx-2"> | </span>
      <router-link to="/read-events" class="inline-block text-white"
        >Eventi in programma</router-link
      >
      <span class="inline-block text-white mx-2"> | </span>
      <router-link to="/read-events-old" class="inline-block text-white"
        >Eventi archiviati</router-link
      >
    </div>
  </div>
</template>

<script>
export default {
  name: "Nav",
  computed: {},
  methods: {
    Logout() {
      console.log("Logout");
      // this.$store.dispatch("set_auth", false);
      this.$store.dispatch("set_auth", false).then(() => {
        this.$router.push({ name: "Home" });
      });
    },
  },
};
</script>

<style scoped lang="scss">
</style>
