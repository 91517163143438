import Vue from 'vue'
import App from './App.vue'
import router from './router'
import store from './store'

// Firebase
// import firebase from './firebase'

// Styles
import './assets/styles/main.css';
// Tailwind
import './assets/styles/tailwind.css'
// Excel
import JsonExcel from 'vue-json-excel'
// Material design
import VueMaterial from 'vue-material'
import 'vue-material/dist/vue-material.min.css'
import 'vue-material/dist/theme/default.css'
Vue.use(VueMaterial)


Vue.config.productionTip = false

new Vue({
    router,
    store,
    render: function(h) { return h(App) }
}).$mount('#app')


// Global Components
Vue.component('downloadExcel', JsonExcel)