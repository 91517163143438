<template>
  <footer class="mt-auto">
    <div class="">
      <div class="container px-4 mx-auto">
        <div class="py-4">
          <p class="text-red text-xs mb-4">
            Gli eventi possono subire modifiche, tieni d'occhio il nostro
            <a
              class="text-red font-bold"
              href="https://www.circololettori.it"
              target="_blank"
              >sito</a
            >!
          </p>
          <p class="text-red text-xs">
          Ai sensi del d.lgs. 196/2003, codice per la protezione dei dati personali, vi informiamo che chi prenderà parte all’evento potrà essere soggetto a riprese fotografiche e/o registrazioni audiovisive. Tali riprese e/o registrazioni potrebbero essere utilizzate sui canali di promozione dei festival e della programmazione giornaliera della Fondazione Circolo dei lettori o pubblicate su carta stampata e altri mezzi di diffusione. L’iscrizione all’evento comporterà il consenso esplicito all’utilizzo della vostra immagine per finalità di carattere informativo e promozionale.
          </p>
        </div>
      </div>
      <div class="bg-red">
        <div class="container px-4 mx-auto">
          <div class="flex justify-between py-4">
            <div class="">
              <!-- {{ state.user.authenticated }} -->
              <div class="" v-if="state.user.authenticated">
                <Nav />
              </div>
            </div>
            <div class="">
              <a
                class="text-white"
                href="https://www.iubenda.com/privacy-policy/78899767"
                target="_blank"
                >Privacy policy</a
              >
            </div>
          </div>
        </div>
      </div>
    </div>
  </footer>
</template>

<script>
// Store
import { mapGetters } from "vuex";
// Components
import Nav from "@/components/Nav.vue";

export default {
  name: "Footer",
  components: {
    Nav,
  },
  computed: {
    ...mapGetters({
      state: "getState",
    }),
  },
  methods: {},
};
</script>

<style scoped lang="scss">
</style>
