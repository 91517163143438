import Vue from 'vue'
import VueRouter from 'vue-router'

// Store
import store from '@/store'

// Components
import Home from '../views/Home.vue'

Vue.use(VueRouter)

const routes = [
    // User
    {
        path: '/',
        name: 'Home',
        component: Home
    },
    {
        path: '/events/event/:id',
        name: 'Event',
        component: function() {
            return import ('../views/Reserve.vue')
        }
    },
    {
        path: '/thank-you',
        name: 'ThankYou',
        component: function() {
            return import ( /* webpackChunkName: "about" */ '../views/ThankYou.vue')
        }
    },
    // Login
    {
        path: '/login',
        name: 'Login',
        component: function() {
            return import ( /* webpackChunkName: "about" */ '../views/auth/Login.vue')
        }
    },
    // Admin
    {
        path: '/create-event',
        name: 'CreateEvent',
        component: function() {
            return import ('../views/admin/CreateEvent.vue')
        },
        beforeEnter(to, from, next) {
            if (store.state.user.authenticated) {
                next();
            } else {
                next('/login');
            }
        }
    },
    {
        path: '/read-events',
        name: 'ReadEvents',
        component: function() {
            return import ('../views/admin/ReadEvents.vue')
        },
        beforeEnter(to, from, next) {
            if (store.state.user.authenticated) {
                next();
            } else {
                next('/login');
            }
        }
    },
    {
        path: '/read-events-old',
        name: 'ReadEventsOld',
        component: function() {
            return import ('../views/admin/ReadEventsOld.vue')
        },
        beforeEnter(to, from, next) {
            if (store.state.user.authenticated) {
                next();
            } else {
                next('/login');
            }
        }
    },
    {
        path: '/update-event/event/:id',
        name: 'UpdateEvent',
        component: function() {
            return import ('../views/admin/UpdateEvent.vue')
        },
        beforeEnter(to, from, next) {
            if (store.state.user.authenticated) {
                next();
            } else {
                next('/login');
            }
        }
    },
    {
        path: '/reservations/event/:id',
        name: 'Reservations',
        component: function() {
            return import ('../views/admin/Reservations.vue')
        },
        beforeEnter(to, from, next) {
            if (store.state.user.authenticated) {
                next();
            } else {
                next('/login');
            }
        }
    },
    // 404
    {
        // path: '/404',
        path: "*",
        name: 'PageNotFound',
        component: function() {
            return import ('../views/404.vue')
        }
    },

]

const router = new VueRouter({
    mode: 'history',
    base: process.env.BASE_URL,
    routes
})

export default router