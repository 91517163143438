import Vue from 'vue'
import Vuex from 'vuex'
// vuex-persistedstate
import createPersistedState from "vuex-persistedstate";

Vue.use(Vuex)

export default new Vuex.Store({
    plugins: [createPersistedState()],
    state: {
        user: {
            authenticated: false,
        }
    },
    mutations: {
        SET_AUTH: (state, value) => {
            state.user.authenticated = value;
        },
    },
    actions: {
        set_auth: (context, value) => {
            context.commit('SET_AUTH', value);
        }
    },
    modules: {},
    getters: {
        getState: (state) => {
            return state
        },
        // getAuth: (state) => {
        //     return state.user
        // },
    }
})