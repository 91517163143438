<template>
  <div class="">
    <Intro msg="Eventi in programma" />
    <div class="mb-12 md:mb-12">
      <div class="container px-4 mx-auto">
        <p class="text-xl text-red">1- seleziona l’evento</p>
        <p class="text-xl text-red">2- compila il form</p>
        <p class="text-xl text-red">3- invia</p>
      </div>
    </div>
    <Read :only_published="true" />
  </div>
</template>

<script>
import Intro from "@/components/component/Intro.vue";
import Read from "@/components/events/Read.vue";

export default {
  name: "Home",
  components: {
    Intro,
    Read,
  },
};
</script>

<style scoped lang="scss">
</style>
