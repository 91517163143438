<template>
  <header class="">
    <div class="bg-red mb-8">
      <div class="container px-4 mx-auto">
        <div class="text-center py-3">
          <router-link to="/" class="inline-block">
            <img
              class="w-44 mx-auto"
              src="../assets/media/images/logo_fondazione.svg"
              alt=""
            />
          </router-link>
        </div>
      </div>
    </div>
  </header>
</template>

<script>
export default {
  name: "Header",
  component: {},
};
</script>

<style scoped lang="scss">
</style>
