<template>
  <div id="app" class="min-h-screen flex flex-col">
    <Header />
    <main class="mb-8">
      <!-- <div>{{ env }}</div> -->
      <router-view />
    </main>
    <Footer />
  </div>
</template>

<script>
import Header from "@/components/Header.vue";
import Footer from "@/components/Footer.vue";

export default {
  name: "App",
  components: {
    Header,
    Footer,
  },
  data() {
    return {
      // env: process.env.NODE_ENV,
    };
  },
};
</script>


<style lang="scss" scoped>
</style>
