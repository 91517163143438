import firebase from "../firebase";

const db = firebase.collection("/events");

class EventsDataService {
    getAll() {
        return db;
    }

    create(event) {
        return db.add(event);
    }

    read(id) {
        return db.doc(id).get();
    }

    update(id, value) {
        return db.doc(id).update(value);
    }

    delete(id) {
        return db.doc(id).delete();
    }

    // deletePartecipantInTheEvent(event_id, user_id) {
    //     console.log("Event: ", event_id);
    //     console.log("User: ", user_id);
    //     return db.doc(event_id).update({
    //         // users: {},
    //         users: users.filter((user) => { user.id !== user_id })
    //     });
    // }

    increaseSeats(id, value) {
        return db.doc(id).update({ occupied_seats: value + 1 });
    }

    decreaseSeats(id, value) {
        return db.doc(id).update({ occupied_seats: value - 1 });
    }

    setSeats(id, value) {
        return db.doc(id).update({ occupied_seats: value });
    }

    // set(id, array, value) {
    //     return db.doc(id).set({
    //         array: value
    //     }, { merge: true });
    // }

}

export default new EventsDataService();