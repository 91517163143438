<template>
  <div class="">
    <div class="container px-4 mx-auto">
      <h1 class="text-2xl text-red uppercase mb-6">{{ msg }}</h1>
    </div>
  </div>
</template> 

<script>
export default {
  name: "Intro",
  props: {
    msg: String,
  },
};
</script>

<style scoped lang="scss">
</style>
